
.App-layout {
    min-height: 100vh;
}

.App-content {
    padding: 30px;
}

.App-header {

}

.App-sidebar {
    padding-top: 60px;
}

.App-profile {
    margin-left: auto;
    text-align: right;
}

.App-footer {
    text-align: center;
}
