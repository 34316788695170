.LoadingPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 112px 0 24px;
  min-height: 100vh;
  height: 100%;
}
